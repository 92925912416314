<template>
  <div class="d-flex">
    <div v-if="item !== null" class="d-flex">
      <template v-if="(item.operator !== 'within' && item.operator !== 'without' && item.operator !== 'inside') && (item.fieldtype === 'Enum' && !(typeof item.agFunc !== 'undefined' && item.agFunc.applyFunction))">
        <multiselect v-model="valueDisplay" class="custom-dropdown-options enable-option-icon fit-label-fix fit-content-fix"
          :max-height="300"
          :options="getOptions(item.field).map(i => i.value)"
          :custom-label="getOptionLabel(item.field)"
          :placeholder="''"
          :searchable="false" 
          :allow-empty="false"
          :showLabels="false">
          <template slot="option" slot-scope="props">
            <font-awesome-icon class="selected-option-icon" v-if="valueDisplay == props.option" :icon="['far', 'check']" />
            <span class="option__title">{{ getOptionLabel(item.field)(props.option) }}</span>
          </template>
        </multiselect>
      </template>
      <div class="d-flex" v-if="(item.operator === 'within' || item.operator === 'without' || (item.operator === 'inside' && item.fieldtype === 'String')) || ((item.fieldtype === 'String' || item.fieldtype === 'Character' || item.fieldtype === 'List<KindData>' || item.fieldtype === 'List<String>') && !(typeof item.agFunc !== 'undefined' && item.agFunc.applyFunction))">
          <b-form-input
            size="md"
            v-model="stringValueDisplay"
            :readonly="disabled"
            @keydown="onKeyDown($event)"
            :placeholder="placeholder">
          </b-form-input>
          <b-form-input class="ml-1"
            v-if="item.operator === 'between' || item.operator === 'outside' || item.operator === 'inside'"
            size="md"
            v-model="stringValueDisplay2"
            :readonly="disabled"
            @keydown="onKeyDown($event)"
            :placeholder="$t('dataview.value')">
          </b-form-input>
      </div>
      <div class="d-flex maxwidth" v-if="(item.operator !== 'within') && (item.fieldtype === 'Float' || item.fieldtype === 'Integer' || item.fieldtype === 'Double' || item.fieldtype === 'Long' || (typeof item.agFunc !== 'undefined' && item.agFunc.applyFunction))">
        <b-form-input
          size="md"
          type="number"
          :min="min"
          :max="max"
          @keydown="onKeyDown($event)"
          v-model="valueDisplay"
          :readonly="disabled"
          :placeholder="placeholder">
        </b-form-input>
        <b-form-input class="ml-1" 
          v-if="item.operator === 'between' || item.operator === 'outside' || item.operator === 'inside'"
          size="md"
          type="number"
          :min="min"
          :max="max"
          @keydown="onKeyDown($event)"
          v-model="valueDisplay2"
          :readonly="disabled"
          :placeholder="$t('dataview.value')">
        </b-form-input>
      </div>
      <div class="d-flex filter-radio-buttons" v-if="item.fieldtype === 'Boolean' && !(typeof item.agFunc !== 'undefined' && item.agFunc.applyFunction)">
        <b-form-radio-group
          class="ml-1 radio-group-class"
          size="sm"
          :disabled="disabled"
          v-model="valueDisplay"
          :options="boolOptions">
        </b-form-radio-group>
      </div>
      <div class="d-flex" v-if="item.fieldtype === 'Date' && item.operator !== 'within' && item.operator !== 'without' && (typeof item.agFunc === 'undefined' || !(item.agFunc.applyFunction))">
        <b-form-datepicker v-if="!useToday" v-model="item.value1"
          class="date-height"
          today-button
          reset-button
          close-button
          hide-header
          :label-today-button="$t('date.today')"
          :label-reset-button="$t('date.reset')"
          :label-close-button="$t('date.close')"
          today-button-variant="primary"
          reset-button-variant="danger" 
          close-button-variant="secondary"
          :disabled="disabled"
        >
          <template v-slot:button-content="{ }">
            <font-awesome-icon :icon="['far', 'calendar-days']" />
          </template>
        </b-form-datepicker>
        <b-form-datepicker class="ml-1 date-height" v-model="item.value2"
          v-if="!useToday && item.operator === 'between' || item.operator === 'outside' || item.operator === 'inside'"
          today-button
          reset-button
          close-button
          hide-header
          :label-today-button="$t('date.today')"
          :label-reset-button="$t('date.reset')"
          :label-close-button="$t('date.close')"
          today-button-variant="primary"
          reset-button-variant="danger" 
          close-button-variant="secondary"
          :disabled="disabled"
        >
          <template v-slot:button-content="{ }">
            <font-awesome-icon :icon="['far', 'calendar-days']" />
          </template>
        </b-form-datepicker>
      </div>
      <div class="d-flex" v-if="item.fieldtype === 'Time' && item.operator !== 'within' && item.operator !== 'without' && (typeof item.agFunc === 'undefined' || !(item.agFunc.applyFunction))">
        <b-form-timepicker v-if="!useToday" v-model="timeDisplay"
          class="date-height"
          today-button
          reset-button
          close-button
          hide-header
          :label-today-button="$t('date.today')"
          :label-reset-button="$t('date.reset')"
          :label-close-button="$t('date.close')"
          today-button-variant="primary"
          reset-button-variant="danger" 
          close-button-variant="secondary"
          :disabled="disabled"
        >
          <template v-slot:button-content="{ }">
            <font-awesome-icon :icon="['far', 'clock']" />
          </template>
        </b-form-timepicker>
        <b-form-timepicker class="ml-1 date-height" v-model="timeDisplay2"
          v-if="!useToday && item.operator === 'between' || item.operator === 'outside' || item.operator === 'inside'"
          today-button
          reset-button
          close-button
          hide-header
          :label-today-button="$t('date.today')"
          :label-reset-button="$t('date.reset')"
          :label-close-button="$t('date.close')"
          today-button-variant="primary"
          reset-button-variant="danger" 
          close-button-variant="secondary"
          :disabled="disabled"
        >
          <template v-slot:button-content="{ }">
            <font-awesome-icon :icon="['far', 'clock']" />
          </template>
        </b-form-timepicker>
      </div>
      <div class="d-flex align-items-center today-check-container" v-if="item.fieldtype === 'Date' && item.operator !== 'within' && item.operator !== 'without' && (typeof item.agFunc === 'undefined' || !(item.agFunc.applyFunction))">
        <b-form-checkbox :disabled="disabled" class="today-check" v-if="item.operator !== 'between' && item.operator !== 'outside' && item.operator !== 'inside'"
          v-model="useToday">{{ $t('use_todays_date') }}</b-form-checkbox>
      </div>
    </div>    
  </div>
</template>


<script>
import { cloneDeep } from 'lodash';
import { timeToMs, msToTime } from '@/helpers';
import Multiselect from 'vue-multiselect';

const KEYCODE_ENTER = 13;

const calendarProperties = [
  {
    "field": "color",
    "type": "String",
    "min": 7,
    "max": 7
  },
  {
    "field": "endDate",
    "type": "Date",
    "min": 0,
    "max": 32503680000000
  },
  {
    "field": "endHour",
    "type": "Time",
    "min": 0,
    "max": 86400000
  },
  {
    "field": "identifier",
    "type": "String",
    "min": 0,
    "max": 200,
    "index": "Composite"
  },
  {
    "field": "isWorking",
    "type": "Boolean",
    "options": [
      "true",
      "false"
    ]
  },
  {
    "field": "name",
    "type": "String",
    "notNull": true,
    "min": 0,
    "max": 200,
    "index": "Mixed"
  },
  {
    "field": "startDate",
    "type": "Date",
    "min": 0,
    "max": 32503680000000
  },
  {
    "field": "startHour",
    "type": "Time",
    "min": 0,
    "max": 86400000
  },
  {
    "field": "type",
    "type": "Enum",
    "options": [
      "Leave",
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Working"
    ],
    "notNull": true
  },
  {
    "field": "uuId",
    "type": "UUID",
    "readOnly": true,
    "index": "Composite"
  }
];

export default {
  name: 'FilterInput',
  components: {
    Multiselect
  },
  props: {
    value: { type: Object, default: null },
    schema: { type: Object, default: null },
    macros: { type: Object, default: null },
    disabled: { type: Boolean, default: false}
  },
  data() {
    return {
      item: null,
      boolOptions: [{ text: 'TRUE', value: true},
                    { text: 'FALSE', value: false}]
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.item = newValue;
      },
      deep: true
    }
  },
  mounted() {
    if (this.value !== null) {
      this.item = cloneDeep(this.value);
    }
    else {
      this.item = null;
    }
  },
  computed: {
    placeholder() {
      if ('inside' === this.item.operator) {
        return 'Value';
      }
      else if ('outside' === this.item.operator) {
        return 'Value';
      }
      else if ('within' === this.item.operator) {
        return 'Value1|Value2|Value3';
      }
      else if ('without' === this.item.operator) {
        return 'Value1|Value2|Value3';
      }
      else if ('regex' === this.item.operator ||
               'containsRegex' === this.item.operator) {
        return '*Value*';        
      }
      
      return this.$t('dataview.value');
    },
    min() {
      if (this.item === null) {
        return 0;
      }
      return this.item.min;
    },
    max() {
      if (this.item === null) {
        return 0;
      }
        
      if (this.item.min === 0 && this.item.max === 1) {
        return 100;
      }
      return this.item.max;    
    },
    useToday: {
      // getter
      get: function () {
        if (this.item === null ||
        (this.item.operator === 'between' || this.item.operator === 'outside' || this.item.operator === 'inside')) {
          return false;
        }
        
        return this.item.value1 === '<NOW>';
      },
      // setter
      set: function (newValue) {
        if (this.item === null) {
          return;
        }
        
        this.$set(this.item, 'value1', newValue ? '<NOW>' : '');
        
        this.$emit('input', this.item);
      }
    },
    stringValueDisplay: {
      // getter
      get: function () {
        if (this.item === null) {
          return '';
        }
        
        return this.item.value1;
      },
      // setter
      set: function (newValue) {
        if (this.item === null) {
          return;
        }
        
        this.$set(this.item, 'value1', newValue);
        
        this.$emit('input', this.item);
      }
    },
    stringValueDisplay2: {
      // getter
      get: function () {
        if (this.item === null) {
          return '';
        }
        
        return this.item.value2;
      },
      // setter
      set: function (newValue) {
        if (this.item === null) {
          return;
        }
        
        this.$set(this.item, 'value2', newValue);
        
        this.$emit('input', this.item);
      }
    },
    valueDisplay: {
      // getter
      get: function () {
        if (this.item === null) {
          return 0;
        }
        
        if (this.item.min === 0 && this.item.max === 1) {
          return Math.trunc(this.item.value1 * 100);
        }
        return this.item.value1;
      },
      // setter
      set: function (newValue) {
        if (this.item === null) {
          return;
        }
        
        if (this.item.min === 0 && this.item.max === 1) {
          this.$set(this.item, 'value1', newValue / 100);
        }
        else {
          this.$set(this.item, 'value1', newValue);
        }
        
        this.$emit('input', this.item);
      }
    },
    timeDisplay: {
      // getter
      get: function () {
        if (this.item === null) {
          return 0;
        }
        
        return msToTime(this.item.value1);
      },
      // setter
      set: function (newValue) {
        if (this.item === null) {
          return;
        }
        
        this.$set(this.item, 'value1', timeToMs(newValue));
        
        this.$emit('input', this.item);
      }
    },
    timeDisplay2: {
      // getter
      get: function () {
        if (this.item === null) {
          return 0;
        }
        
        return msToTime(this.item.value1);
      },
      // setter
      set: function (newValue) {
        if (this.item === null) {
          return;
        }
        
        this.$set(this.item, 'value1', timeToMs(newValue));
        
        this.$emit('input', this.item);
      }
    },
    valueDisplay2: {
      // getter
      get: function () {
        if (this.item === null) {
          return 0;
        }
        
        if (this.item.min === 0 && this.item.max === 1) {
          return Math.trunc(this.item.value2 * 100);
        }
        return this.item.value2;
      },
      // setter
      set: function (newValue) {
        if (this.item === null) {
          return;
        }
        
        if (this.item.min === 0 && this.item.max === 1) {
          this.$set(this.item, 'value2', newValue / 100);
        }
        else {
          this.$set(this.item, 'value2', newValue);
        }
        
        this.$emit('input', this.item);
      }
    }
  },
  methods: {
    getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === 'undefined' ? event.keyCode : event.which;
    },
    onKeyDown(event) {
      const keyCode = this.getCharCodeFromEvent(event);
      if (KEYCODE_ENTER == keyCode) {
        this.$emit('enterKey');
      }
    },
    getLabel(value) {
      if (value === 'Project') {
        return 'Summary Task';
      }
      return value;
    },
    getOption(value) {
      if (value === "asap") {
        return { value: "As_soon_as_possible", text: this.$t('constraint_type.As_soon_as_possible') };
      }
      else if (value === "alap") {
        return { value: "As_late_as_possible", text: this.$t('constraint_type.As_late_as_possible') };
      }
      else if (value === "snet") {
        return { value: "Start_no_earlier_than", text: this.$t('constraint_type.Start_no_earlier_than') };
      }
      else if (value === "snlt") {
        return { value: "Start_no_later_than", text: this.$t('constraint_type.Start_no_later_than') };
      }
      else if (value === "fnet") {
        return { value: "Finish_no_earlier_than", text: this.$t('constraint_type.Finish_no_earlier_than') };
      }
      else if (value === "fnlt") {
        return { value: "Finish_no_later_than", text: this.$t('constraint_type.Finish_no_later_than') };
      }
      else if (value === "mso") {
        return { value: "Must_start_on", text: this.$t('constraint_type.Must_start_on') };
      }
      else if (value === "mfo") {
        return { value: "Must_finish_on", text: this.$t('constraint_type.Must_finish_on') };
      }
    },
    getOptions(field) {
      const fieldlist = field.split('.');
      const index = fieldlist.length;
      if (index <= 1) {
        return [];
      }
      
      if (fieldlist[index - 2] !== null &&
          this.schema !== null) {
        const properties = fieldlist[index - 2] === 'CALENDAR' ? calendarProperties : this.schema[fieldlist[index - 2]].properties;
        if (properties !== null) {
          const property = fieldlist[fieldlist.length - 1];
          const field = properties.filter(p =>  p.field === property);
          if (field.length !== 0) {
            if (property === 'taskType') {
              return field[0].options.filter(opt => opt !== 'Master').map(o => { return { value: o, text: this.getLabel(o) } });
            }
            else if (property === 'constraintType') {
              return field[0].options.map(o => { return this.getOption(o) });
            }
            return field[0].options.map(o => { return { value: o, text: o } });
          }
        }
      }
      
      return [];
    },
    getOptionLabel(field) {
      const opts = this.getOptions(field);
      return (value) => {
        return opts.find(i => i.value === value)?.text || value;
      }
    }
  }
}
</script>

<style lang="scss">
.maxwidth {
  max-width: 200px;
}

.today-check-container {
  height: 35px;
}

.today-check {
  margin-left: 4px;
}

.filter-radio-buttons {
  height: 35px;
  align-items: center;
}

.filter-radio-buttons .radio-group-class {
  top: 2px;
}

.date-height {
  height: 35px !important;
}

@media only screen and (max-width: 768px) {
  .date-height {
    height: auto !important;
  }
}
</style>