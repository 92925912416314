var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: "DATAVIEW_FORM" },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.labelTitle,
            visible: _vm.show,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            id: "dataview-modal",
          },
          on: { ok: _vm.modalOk, hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.modalOk },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              on: { dismissed: _vm.dismissAlert },
              model: {
                value: _vm.errorShow,
                callback: function ($$v) {
                  _vm.errorShow = $$v
                },
                expression: "errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _vm.dataviewOptions.length !== 0
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "mt-2 mb-2 d-block" }, [
                    _vm._v(" " + _vm._s(_vm.$t("dataview.field.name")) + " "),
                  ]),
                  _c("b-form-input", {
                    model: {
                      value: _vm.dataviewEditName,
                      callback: function ($$v) {
                        _vm.dataviewEditName = $$v
                      },
                      expression: "dataviewEditName",
                    },
                  }),
                  _c("div", { staticClass: "mt-3 mb-2 d-block" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("dataview.select.component")) + " "
                    ),
                  ]),
                  _c("b-form-select", {
                    attrs: { options: _vm.dataviewOptions },
                    model: {
                      value: _vm.dataviewComponent,
                      callback: function ($$v) {
                        _vm.dataviewComponent = $$v
                      },
                      expression: "dataviewComponent",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "loading" },
                [
                  _c("b-spinner", {
                    attrs: { variant: "primary", label: "Loading..." },
                  }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }